<template>
    <ion-page>
        <ion-content :fullscreen="true" class="ion-no-padding">
            <ion-item lines="full" mode="md" v-for="quadrant of Object.keys(quadrants)" :key="quadrant"  @click="goToJourneySets(quadrants[quadrant])">
                <ion-icon slot="start" :src="language.quadrants[quadrant].icon" color="primary" class="journey-set-type-icon" />
                <ion-label>
                    <h2>{{  journeySetTypes.get(quadrants[quadrant]) }}</h2>
                    <p>{{ getTranslatedNumberText(quadrants[quadrant]) }}</p>
                </ion-label>
                <DirectionIndicator class="edit-forward" :is-ltr-forward="true"/>
            </ion-item>
        </ion-content>
        <FixedFooter :buttons="footerButtons" v-if="canCreate"/>
    </ion-page>
</template>

<script lang="ts">
import {computed, defineComponent, ref} from "vue";
import { execute } from "@/mixins/LoadingMixin";
import {
    IonContent,
    IonPage,
    IonLabel,
    IonItem,
    IonIcon,
} from "@ionic/vue";
import { ellipseOutline } from 'ionicons/icons';
import { getJourneySetGroups } from "@/services/JourneySetService";
import {JOURNEY_SET_TYPES, JourneySetTypeId} from "@/models/JourneySetTypes";
import router from "@/router";
import {useStore} from "vuex";
import { getTranslatedNumber } from "@/utils/NumberTranslate";
import DirectionIndicator from "@/components/DirectionIndicator.vue";
import FixedFooter from "@/components/FixedFooter.vue";
import {FooterButtonsBuilder} from "@/mixins/FooterButtonsBuilder";

export default defineComponent({
    name: "JourneySetGroups",
    components: {
        IonContent,
        IonPage,
        IonLabel,
        IonItem,
        IonIcon,
        DirectionIndicator,
        FixedFooter
    },
    async ionViewWillEnter() {
        const currentUser = this.store.getters['auth/loggedInUser'];
        this.canCreate = currentUser.permissions?.includes("CreateJourneySet");
        await this.store.dispatch('app/setPageTitle', this.published ? this.language.publishedJourneySets : this.language.allJourneySets);
        await execute(async () => {
            this.journeySetGroups = await getJourneySetGroups(this.store.getters['app/selectedAppInstanceId'], this.published);
        }, this.language.errors.loadingJourneySets);
    },
    setup() {
        const store = useStore();
        const canCreate = ref(false);
        const journeySetGroups = ref({} as { [key: string]: number });
        const language =  computed(() => store.getters['app/language']);
        const published = computed(() => router.currentRoute.value.name === 'publishedJourneySetGroups');
        
        const goToJourneySets = (typeId: string) => {
            const numberOfJourneys = journeySetGroups.value[typeId];
            if (!published.value || numberOfJourneys > 0 ) {
                router.push({name: published.value ? 'publishedJourneySets' : 'journeySets', params: {typeId: typeId}});
            }
        }
        
        const getTranslatedNumberText = (typeId: string) => {
            const numberOfJourneys = journeySetGroups.value[typeId];
            return getTranslatedNumber(numberOfJourneys || 0) + ' ' + (numberOfJourneys === 1 ? language.value.journeySet : language.value.journeySets);
        }

        return {
            getTranslatedNumber,
            store,
            language,
            journeySetGroups,
            journeySetTypes: JOURNEY_SET_TYPES,
            footerButtons: computed(() => 
                    new FooterButtonsBuilder()
                .addClearButton(false, () => { router.push({ name: 'journeySetInstructions'}); }, language.value.addNewJourneySet)
                .create()
            ),
            canCreate,
            quadrants: JourneySetTypeId as { [key: string]: string },
            ellipseOutline,
            goToJourneySets,
            getTranslatedNumberText,
            published
        }
    }
});
</script>

<style scoped>
</style>